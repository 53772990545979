import React, {useContext, useState} from 'react';
import '../form-input-scanner-and-file/form-input-scanner-dynamsoft-style.css';
import {FileContext} from './form-input-file-context';
import {AppContext} from '../../../app/context';
import CameraIcon from '@material-ui/icons/CameraAlt';
import Tooltip from '@material-ui/core/Tooltip';
import { FileDisplayEditService } from "../../../../services/file-display-edit-service";
import { FormContext, FormContextProvider } from 'components/forms/dynamic-form/dynamic-form-context';
var flgId = 0;
var flgInv = 0;
var flgProcuracao = 0;
var flgCrCard = 0;
var flgCrCard2 = 0;
var flgCnt = 0;
var flgPmCnt = 0;
var flgOldId = 0;

const fileToBase64 = (evt, file) => {
    return new Promise(resolve => {
        var reader = new FileReader();
        reader.onload = function (event) {
            resolve(event.target.result);
        };
        reader.readAsBinaryString(file);
    });
};


const handleFileChange = (onChange, files, params, formValues, fileName) => (event) => {
    files.forEach(file => console.log(file))

    if (params.has('PACKAGE-ID')) {
        if(fileName === "id" && flgId == 0){    
            files.length = 0;
            flgId = 1;
        }
        if(fileName === "crCardRt" && flgCrCard == 0){    
            files.length = 0;
            flgCrCard = 1;
        }
        if(fileName === "crCardRt2" && flgCrCard2 == 0){    
            files.length = 0;
            flgCrCard2 = 1;
        }
        if(fileName === "inv" && flgInv == 0){    
            files.length = 0;
            flgInv = 1;
        }
        if(fileName === "cnt" && flgCnt == 0){    
            files.length = 0;
            flgCnt = 1;
        }
        if(fileName === "procuracao" && flgProcuracao == 0){    
            files.length = 0;
            flgProcuracao = 1;
        }
        if(fileName === "pmCntPlDv" && flgPmCnt == 0){    
            files.length = 0;
            flgPmCnt = 1;
        }
        if(fileName === "oldUserId" && flgOldId == 0){    
            files.length = 0;
            flgOldId = 1;
        }

        flgId = 0;
        flgCnt = 0;
        flgCrCard = 0;
        flgCrCard2 = 0;
        flgInv = 0;
        flgPmCnt = 0;
        flgProcuracao = 0;
        flgOldId = 0;
    }
    
    Array.from(event.currentTarget.files).forEach((file) => {
        fileToBase64(event, file).then((base64Image) => {
            onChange({size: file.size, image: btoa(base64Image)});
        });
    });
    // Fix for EFH340-588
    event.currentTarget.value = null;
};


const FileInputView = ({metadata}) => {
    const {files, handleChange } = useContext(FileContext);
    const { formValues } =  useContext(FormContext);
    const [chkValue, setChkValue] = useState(false);
    const {language, appmetadata} = useContext(AppContext);
    const params = new URLSearchParams(window.location.search)
    let fieldDisabled = false;
    let classes = 'form-button form-button-secondary'

    if (params.has('PACKAGE-ID')){
        if (metadata.updatable === false) {
            classes = 'form-button form-button-disabled'
            fieldDisabled = true
        }
    }

    return (
        <div style={{'width': '20%'}}>
            <Tooltip title={appmetadata.FORM.ATTACH[language]}>
                <label htmlFor={metadata.name} className={classes}>
                    <CameraIcon/>
                </label>
            </Tooltip>
            <div className={'hidden'}>
                <input type="file" id={metadata.name}
                       onChange={handleFileChange(handleChange, files, params, formValues, metadata.name)}
                       accept={metadata.accept}
                       disabled={fieldDisabled}
                       multiple={Boolean(metadata.multiple)}
                       onClick={()=>setChkValue(!chkValue)}
                       />
                       
            </div>
          <label>
          <input className="hidden" id={metadata.name} type="checkbox" checked={chkValue}/>
          </label>
        </div>
    );
};

export default FileInputView;