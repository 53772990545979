export const LANGUAGE = {
  PT_BR: "PT_BR",
  EN_US: "EN_US"
};

export const STRINGS = {
  HEADER: {
    TITLE: {
      PT_BR: "Portal de Documentação de Clientes",
      EN_US: "Web Application"
    },
    HELLO_USER: {
      PT_BR: "Olá, ",
      EN_US: "Hello, "
    },
    CREATE_PACKAGE: {
      PT_BR: "Criar Pacote",
      EN_US: "Create Package"
    },
    SEARCH: {
      PT_BR: "Busca",
      EN_US: "Search"
    },
    USER: {
      PT_BR:"Usuário",
      EN_US:"User"
    },
    LOGOUT: {
      PT_BR:"Logout",
      EN_US:"Logout"
    },
    HELP: {
      PT_BR:"Ajuda",
      EN_US:"Help"
    },
  },
  HOME_PAGE:{
    PACKAGE_CARD: {
      TITLE: {
        PT_BR: "Criar Pacote",
        EN_US: "Create Package"
      },
      DESCRIPTION: {
        PT_BR:"Finalizar venda de produtos, cadastrando documentos pessoais",
        EN_US:"Finalize product sale registering personal documents"
      },
    },
    CORPORATE_SEARCH_CARD: {
      TITLE: {
        PT_BR: "Buscar Pacotes Corporativos",
        EN_US: "Search Corporate Packages"
      },
      DESCRIPTION: {
        PT_BR:"Procurar por pacotes de documentos corporativos",
        EN_US:"Search for document corporate packages"
      },
    },
    PREPAID_SEARCH_CARD: {
      TITLE: {
        PT_BR: "Buscar Móvel Pré",
        EN_US: "Search Prepaid Mobile"
      },
      DESCRIPTION: {
        PT_BR:"Pacotes de vendas de serviços para clientes com planos Pré-Pago",
        EN_US:"Search for document prepaid packages"
      },
    },
    TIMLIVE_SEARCH_CARD: {
      TITLE: {
        PT_BR: "Buscar TIM Live",
        EN_US: "Search TIM Live"
      },
      DESCRIPTION: {
        PT_BR:"Pacotes de vendas e serviços para clientes com planos TIM Live",
        EN_US:"Search for document tim live packages"
      },
    },
    MESATHALES_SEARCH_CARD: {
      TITLE: {
        PT_BR: "Mesa Thales",
        EN_US: "Mesa Thales"
      },
      DESCRIPTION: {
        PT_BR:"Análise de pacotes roxos",
        EN_US:"Analysis of purple packages"
      },
    },
    SEARCH_CARD:{
      TITLE: {
        PT_BR:"Buscar Pacotes",
        EN_US:"Search Packages"
      },
      DESCRIPTION: {
        PT_BR:"Procurar por pacotes de documentos",
        EN_US:"Search for document packages"
      },
    },
    MIGRATION_CARD:{
      "TITLE": {
        "PT_BR":"Migrar Pacotes",
        "EN_US":"Packages Migration"
      },
      "DESCRIPTION": {
        "PT_BR":"Procurar por pacotes de documentos antigos",
        "EN_US":"Search for old document packages"
      }
    }
  },
  FORM:{
    VIEW_DELETE: {
      PT_BR:"Ver/Deletar",
      EN_US:"View/Delete"
    },
    VIEW: {
      PT_BR:"Ver",
      EN_US:"View"
    },
    ATTACH: {
      PT_BR:"Anexar",
      EN_US:"Attach"
    },
    NO_FILE: {
      PT_BR:"Nenhum Arquivo",
      EN_US:"No Files"
    },
    NUMBER_OF_ATTACHED_FILES: {
      PT_BR:(numberOfFiles) => `${numberOfFiles} arquivo(s)`,
      EN_US:(numberOfFiles) => `${numberOfFiles} file(s)`
    },
    DONT_SELECT_MORE_THAN_N_FILES: {
      PT_BR:(numberOfFiles) => `Não selecionar mais que ${numberOfFiles} arquivo(s)`,
      EN_US:(numberOfFiles) => `Don't select more than ${numberOfFiles} file(s)`
    },
    DONT_FILE_SIZE_EXCEEDS_LIMIT: {
      PT_BR:(fileSizeLimit) => `Tamanho do arquivo excede o limite de ${fileSizeLimit}`,
      EN_US:(fileSizeLimit) => `File size exceeds limit ${fileSizeLimit}`
    },
    DONT_EACH_FILE_SIZE_MIN_LIMIT: {
      PT_BR:(fileSizeMinLimit) => `Tamanho do arquivo deve ser maior que ${fileSizeMinLimit}`,
      EN_US:(fileSizeMinLimit) => `Size of each file must be above ${fileSizeMinLimit}`
    },
    SUBMIT: {
      PT_BR: "Enviar",
      EN_US: "Submit"
    },
    SCAN: {
      PT_BR: "Escanear",
      EN_US: "Scan"
    }
  },
  CONSULT:{
    DOWNLOAD: {
      PT_BR: "Baixar",
      EN_US: "Download"
    },
    SEE: {
      PT_BR: "Ver",
      EN_US: "See"
    },
    ALL_FILES: {
      PT_BR: "Todos os Arquivos",
      EN_US: "All files"
    },
    EXPORT_TABLE:{
      PT_BR: "Exporta o conteúdo da busca atual considerando os filtros selecionados. Limitado a 2.000 linhas e a 30 dias corridos contados da data final filtrada, se houver.",
      EN_US: "Export the search content considering the selected filters. Limited to 2,000 rows and 30 days before the end date fitlered, in case existant."
    },
    EXPORT_ALL:{
      PT_BR: "Exporta o conteúdo completo sem considerar os filtros selecionados. Limitado a 10.000 linhas e a 30 dias corridos contados da data vigente (hoje).",
      EN_US: "Export all the content, without filters. Limited to 10,000 rows and 30 days before the end date fitlered, in case existant."
    },
  },
};