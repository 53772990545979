import React, { useState, useEffect, useContext, useCallback, createContext }from 'react';
import { arrayRemove, base64Size } from '../../../../utils';
import { FileDisplayEditService } from "../../../../services/file-display-edit-service";
import { FormContext } from "../../dynamic-form/dynamic-form-context";
import {AppContext} from "../../../app/context";
import {STRINGS} from "../../../../variables/display-strings";
import { FILESIZE_THRESHHOLD_SUM, MIN_FILESIZE_EACH } from "../../../../config";
export const FileContext = createContext({
    handleChange: () => {},
    valid: 1,
    validated: 0,
    feedback: "",
    files: []
});
let flag = 0;
export let arrayFiles = [];
export const FileContextProvider = (props) => {
    const [files, setFiles] = useState([]);
    const [valid, setValid] = useState(0);
    const [validated, setValidated] = useState(0);
    const [feedback, setFeedback] = useState(false);
    const { setFormValues, formValues } = useContext(FormContext);
    const {language} = useContext(AppContext);

    const addFile = useCallback((file) => {
        const params = new URLSearchParams(window.location.search)
 
        if (params.has('PACKAGE-ID') ){
            arrayFiles.push(props.metadata.name);
           
        }
        console.log(arrayFiles)
        if (!Object.prototype.hasOwnProperty.call(file, 'image')){
            file = {image: file, size: base64Size(file)}
        }
        const hasSimilarImage = files.some( (item) => file.image === item.image);
        if(!hasSimilarImage)
            setFiles(files => {
                FileDisplayEditService.setFileList([...files, file].reverse());
                FileDisplayEditService.setTitle(props.metadata.label);
                return [...files, file]
            });
    }, [files]);

    const removeFile = useCallback((image) => {
        setFiles(prevFiles => [...arrayRemove(prevFiles, image)]);
    }, [files]);

    useEffect(() => {
        const fileDeleteSubscription = FileDisplayEditService.getFileToBeDeleted()
            .subscribe((image) => {
                removeFile(image);
            });
        return () => {
            fileDeleteSubscription.unsubscribe()
        }
    }, [removeFile]);

    const payload_exists = Boolean(formValues[props.metadata.name]);

    useEffect(() => {
        setFiles(((formValues[props.metadata.name] || [])['value'])|| []);
    }, [payload_exists]);

    const validateFileSizes = (files) => {

        let totalFileSizes = 0;
        let minSizeFound = false;
        let feedback = {message: '', hasError : false};
        const params = new URLSearchParams(window.location.search);

        files.forEach( (file) => {
            totalFileSizes += file.size;
            if(file.size < MIN_FILESIZE_EACH) {
                minSizeFound = true;
            }
        });

        if(totalFileSizes > FILESIZE_THRESHHOLD_SUM) {
            feedback.message = `*${STRINGS.FORM.DONT_FILE_SIZE_EXCEEDS_LIMIT[language]('3MB')}`;
            feedback.hasError = true;
            return feedback;
        }
        if (!params.has('PACKAGE-ID') ){
            if(minSizeFound) {
                feedback.message = `*${STRINGS.FORM.DONT_EACH_FILE_SIZE_MIN_LIMIT[language]('20KB')}`;
                feedback.hasError = true;
            }
        }
    
        return feedback;
    };

    const validateFileList = (fileList) => {
        const {metadata} = props;
        setFeedback("");
        if ((Boolean(metadata.multiple) && files.length > metadata.itemLimit) || (!Boolean(metadata.multiple) && files.length > 1)) {
            setValid(false);
            setValidated(true);
            setFormValues(props.metadata.name, fileList, 0);
            setFeedback(`*${STRINGS.FORM.DONT_SELECT_MORE_THAN_N_FILES[language](metadata.itemLimit || 1)}`);
            return;
        }
        else if (files.length===0 ){
            if (metadata.required){
                setValid(false);
                setFormValues(props.metadata.name, fileList, 0);
                return;
            }
            else{
                setFormValues(props.metadata.name, fileList, 1);
                setValid(true);
            }
        }
        else{
            setValid(true);
            setFormValues(props.metadata.name, fileList, 1);
        }

        if(files.length) {
            let feedback = validateFileSizes(files);
            if(feedback.hasError ) {
                setValid(false);
                setValidated(1);
                setFormValues(props.metadata.name, fileList, 0);
                setFeedback(feedback.message);
            }
        }
        
    };

    useEffect(() =>{
        validateFileList(files);
    }, [files]);

    const handleChange = (image) => {
        addFile(image);
    };

    return (
        <FileContext.Provider value={{
            handleChange: handleChange,
            valid: valid,
            validated: validated,
            feedback: feedback,
            files: files
        }}>
            {props.children}
        </FileContext.Provider>
    );
};

export const FileContextConsumer = FileContext.Consumer;